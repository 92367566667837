<div class="" style="border-top: 1px solid #f1f1f1;">
  <mat-progress-bar mode="indeterminate" *ngIf="!isLoaded"></mat-progress-bar>
  <div *ngIf="(products | filter:this.globals.Store.SearchText)?.length == 0 && isLoaded" class="w-100 text-center" style="padding: 100px 0;">
    We're sorry. We cannot find the product you are looking for.
  </div>

  <ng-container *ngIf="globals.Store.VendorConfig.EnableSlider">
    <div id="carouselExampleIndicators" class="carousel slide carousel__container my-3" data-ride="carousel">
      <div class="carousel-inner carousel__slideContainer">
        <div *ngFor="let slide of globals.Store.VendorConfig.Slides; index as i" [ngClass]="{'carousel-item carousel__imageContainer': true, 'active': i == 0}"
          [attr.data-interval]="globals.Store.VendorConfig.SlideTimeout">
          <img class="carousel__image d-block w-100" [src]="slide" />
        </div>
      </div>
      <a class="carousel-control-prev carousel__controll carousel__controll-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon carousel__controllIcon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next carousel__controll carousel__controll-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon carousel__controllIcon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
    </div>
  </ng-container>

  <div *ngIf="isLoaded" class="row card-group ">
    <ng-container *ngIf="globals.Store.VendorConfig.ProductListFormat === 'list'">
      <div class="col-12 productContainerList" *ngFor="let product of products | filter:this.globals.Store.SearchText">
        <app-catalog-list-view [product]="product"></app-catalog-list-view>
      </div>
    </ng-container>
    <ng-container *ngIf="globals.Store.VendorConfig.ProductListFormat === 'grid'">
      <div class="col-6 col-sm-6 col-md-3 p-1" *ngFor="let product of products | filter:this.globals.Store.SearchText">
        <app-catalog-grid-view [product]="product"></app-catalog-grid-view>
      </div>
    </ng-container>
  </div>
</div>
<ng-container *ngIf="globals.Store.VendorConfig.EnableSalesPopup">
  <app-sales-pop-up></app-sales-pop-up>
</ng-container>
